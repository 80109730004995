import {} from 'jquery-ujs';
import Noty from 'noty';

// Override default Noty settings
Noty.overrideDefaults({
    type: 'alert',
    layout: 'topRight',
    theme: 'applepie',
    text: '',
    timeout: 2000,
    progressBar: true,
    closeWith: ['click'],
    animation: {
        open: 'noty_effects_open',
        close: 'noty_effects_close',
    },
    id: false,
    force: false,
    killer: false,
    queue: 'global',
    container: false,
    buttons: [],
    callbacks: {
        beforeShow: null,
        onShow: null,
        afterShow: null,
        onClose: null,
        afterClose: null,
        onClick: null,
        onHover: null,
        onTemplate: null,
    },
    sounds: {
        sources: [],
        volume: 1,
        conditions: [],
    },
    titleCount: {
        conditions: [],
    },
    modal: false,
    visibilityControl: false,
});

$(window).resize(function () {
    resizeModal();
});

$(document).keyup(function (e) {
    //If you press escape just mass clean up view interactions.
    if (e.keyCode == 27) {
        escapeModal();
    }
});

$(document).on('mousedown', '.loading', function (e) {
    e.preventDefault();
});

$(document).on('click', 'a[target=_new]', function (e) {
    e.preventDefault();
    newWindow($(this).attr('href'));
});

// Microsoft login button logic
$(document).ready(function () {
    const $emailInput = $('input[name="user_session[email]"]');
    const $passwordInput = $('input[name="user_session[password]"]');
    const $loginButton = $('#login-button');

    if ($emailInput.length) {
        const handleEmailInput = () => {
            const emailValue = $emailInput.val().toLowerCase();
            const isMicrosoftEmail = emailValue.includes('@arcsupport.ca');
            const isProduction = window.location.hostname === 'arc-connect.ca';

            if (isMicrosoftEmail && isProduction) {
                $passwordInput.addClass('hidden');
                $emailInput.removeClass('pad-bottom');
                $loginButton
                    .val('Login with Microsoft')
                    .addClass('microsoft-login');
            } else {
                $passwordInput.removeClass('hidden');
                $emailInput.addClass('pad-bottom');
                $loginButton.val('Login').removeClass('microsoft-login');
            }
        };

        $emailInput.on('input', handleEmailInput);

        // Run once on page load in case the field is prefilled
        handleEmailInput();
    }
});

document.addEventListener('turbo:load', function () {
    prepareTooltips();
});

function openModal(partial) {
    var window_width = $(window).width();
    var window_height = $(window).height();

    if ($('#modal').length) {
        //This is only really called when the form submission fails
        $('#modal').replaceWith(partial);
    } else {
        $('#container').prepend("<div id='overlay'></div>");
        $('#container').prepend(partial);
    }

    resizeModal();
    prepareTooltips();

    //Lockout modal when "load" button is pressed. Usually the submit will do this.
    $('#modal').on('click', '.load', function () {
        $('#overlay').addClass('loading'); //If overlay has this class escape or clicking it are disabled.
        $('#modal input, #modal select, #modal textarea').addClass('loading'); //Block out all inputs in modal.
        $(this).prop('value', 'Loading...'); //Changed text for some visual feedback.
        $('#container').prepend(
            "<div id='loading-frame'><div class='loading-animation'></div></div>"
        ); //Show spinner!!!
    });

    $('#overlay')
        .click(function () {
            //Allow clicks on the overlay to dismiss modal.
            escapeModal();
        })
        .children()
        .click(function () {
            //Don't allow clicks on the actual modal to dismiss. Not even needed anymore because modal is no longer inside overlay but who cares.
            return false;
        });
}

function closeModal() {
    $('#overlay').remove();
    $('#modal').remove();
    $('#loading-frame').remove();
}

// Don't call this one outside of this document. It's for clicking the overlay and escape keying a modal only.
// Don't want to call this one on update\create because it won't kill the modal because of the loading classes.
// The only way loading classes are removed is through closeModal(); which is required for app flow.
function escapeModal() {
    if (!$('#overlay').hasClass('loading')) {
        $('#overlay').remove();
        $('#modal').remove();
        $('#loading-frame').remove();
    }
}

function resizeModal() {
    $('#modal').css('left', ($(window).width() - $('#modal').outerWidth()) / 2);
    $('#modal').css(
        'top',
        ($(window).height() - $('#modal').outerHeight()) / 3
    );
}

function newWindow(url) {
    if (window.innerWidth <= 640) {
        // if width is smaller then 640px, create a temporary a elm that will open the link in new tab
        var a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');

        var dispatch = document.createEvent('HTMLEvents');
        dispatch.initEvent('click', true, true);

        a.dispatchEvent(dispatch);
    } else {
        var width = window.innerWidth * 0.66;
        // define the height in
        var height = (width * window.innerHeight) / window.innerWidth;
        // Ratio the hight to the width as the user screen ratio
        window.open(
            url,
            'newwindow',
            'width=' +
                width +
                ', height=' +
                height +
                ', top=' +
                (window.innerHeight - height) / 2 +
                ', left=' +
                (window.innerWidth - width) / 2
        );
    }
}

function prepareTooltips() {
    $('.tooltip-target').on('mousemove', function (e) {
        var tip = $(this).next('.tooltip-container');
        var top = e.clientY - tip.outerHeight() - 10;
        var left = e.clientX - tip.outerWidth() / 2;
        tip.css({
            top: top + 'px',
            left: left + 'px',
            display: 'inline-block',
        });
    });
    $('.tooltip-target').on('mouseout', function () {
        $(this).next('.tooltip-container').css('display', 'none');
    });
}

//Noty is a jquery DOM-level notification plugin. Not same as HTML5 desktop based notifications below.

function flash(json) {
    var flashes = jQuery.parseJSON(json);

    if (flashes.length > 0) {
        for (var i = 0; i < flashes.length; i++) {
            if (
                flashes[i][0] == 'warning' ||
                flashes[i][0] == 'error' ||
                flashes[i][0] == 'alert'
            ) {
                new Noty({
                    text: htmlEscape(flashes[i][1]),
                    type: flashes[i][0],
                    timeout: false,
                }).show();
            } else {
                new Noty({
                    text: htmlEscape(flashes[i][1]),
                    type: 'success',
                }).show();
            }
        }
    }
}

function htmlEscape(str) {
    return (
        str
            // .replace(/&/g, '&amp;') disabled this one to allow &entities;
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
    );
}

// Assign to global scope for inline scripts
Object.assign(window, {
    // Third-party libraries
    Noty,
    // Local functions
    openModal,
    closeModal,
    newWindow,
    flash,
    htmlEscape,
});
